import React from "react";

import "./WorkSteps.scss";

const WorkSteps = () => {
  return (
    <>
      <div className="workSteps__title contentBlock__title">Этапы работ</div>
      <div className="workStepsContentWrapper">
        <div className="workSteps__block">
          <div className="workSteps__block__title">1</div>
          <div className="workSteps__block__text">
            Сбор и анализ документации, комплексное изучение природных условий
            участка
          </div>
        </div>
        <div className="workSteps__block">
          <div className="workSteps__block__title">2</div>
          <div className="workSteps__block__text">
            Разработка проекта: сноса и разборки здания, составление сметы
          </div>
        </div>
        <div className="workSteps__block">
          <div className="workSteps__block__title">3</div>
          <div className="workSteps__block__text">
            Получение разрешений и согласование в ответственных органах
          </div>
        </div>
        <div className="workSteps__block">
          <div className="workSteps__block__title">4</div>
          <div className="workSteps__block__text">
            Подготовка площадки и установка защитных ограждений
          </div>
        </div>
        <div className="workSteps__block">
          <div className="workSteps__block__title">5</div>
          <div className="workSteps__block__text">
            Отключение всех коммуникаций
          </div>
        </div>
        <div className="workSteps__block">
          <div className="workSteps__block__title">6</div>
          <div className="workSteps__block__text">
            Снос строений: <br /> -стен, перекрытий <br /> -полный разбор зданий
            и производственных сооружений <br /> -разбор фундамента объекта
          </div>
        </div>
        <div className="workSteps__block">
          <div className="workSteps__block__title">7</div>
          <div className="workSteps__block__text">
            Вывоз строительных отходов и очистка территории
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkSteps;
