import React from "react";

import { Button } from "carbon-components-react";

import "./Price.scss";

const Price = () => {
  const rows = [
    {
      id: "1",
      service: "Снос зданий",
      price: "50",
      unit: (
        <>
          м<sup>3</sup>
        </>
      ),
    },
    {
      id: "2",
      service: "Демонтаж зданий с полным или частичным сохранение материалов",
      price: "600",
      unit: (
        <>
          м<sup>3</sup>
        </>
      ),
    },
    {
      id: "3",
      service: "Погрузка, вывоз и утилизация отходов сноса и демонтажа",
      price: "200",
      unit: (
        <>
          м<sup>3</sup>
        </>
      ),
    },
    {
      id: "4",
      service: "Демонтаж внутренних конструкций зданий с сохранением фасада",
      price: "600",
      unit: (
        <>
          м<sup>3</sup>
        </>
      ),
    },
    {
      id: "5",
      service:
        "Демонтаж железобетонных фундаментов (механизированным способом)",
      price: "2000",
      unit: (
        <>
          м<sup>3</sup>
        </>
      ),
    },
    {
      id: "6",
      service: "Демонтаж железобетонных фундаментов (вручную)",
      price: "3500",
      unit: (
        <>
          м<sup>3</sup>
        </>
      ),
    },
    {
      id: "7",
      service: "Демонтаж железобетонных заборов",
      price: "1500",
      unit: "м.п",
    },
    {
      id: "8",
      service: "Демонтаж кирпичных стен (вручную)",
      price: "1800",
      unit: (
        <>
          м<sup>3</sup>
        </>
      ),
    },
    {
      id: "9",
      service: "Механизированный демонтаж кирпичных труб высотой не более 27 м",
      price: "10000",
      unit: "м.п",
    },
    {
      id: "10",
      service: "Ручной демонтаж кирпичных труб высотой не более 27 м",
      price: "25000",
      unit: "м.п",
    },
    {
      id: "11",
      service:
        "Демонтаж металлоконструкций без помощи грузоподъемных механизмов",
      price: "2000",
      unit: "т",
    },
    {
      id: "12",
      service: "Демонтаж металлоконструкций с применением кранов и автовышек",
      price: "3000",
      unit: "т",
    },
  ];

  return (
    <>
      <div className="price__title contentBlock__title">
        Цены на снос и демонтаж зданий
      </div>
      <div className="priceTabWrapper">
        <div className="price__tab">
          <div className="price__tab__header">
            <div className="tab__col tab__col-1">Услуга</div>
            <div className="tab__col tab__col-2">Цена (₽)</div>
            <div className="tab__col tab__col-3">Единица измерения</div>
          </div>
          <div className="vertLine"></div>
          {rows.map((item) => (
            <div key={item.id}>
              <div className="price__tab__row">
                <div className="tab__col tab__col-1">{item.service}</div>
                <div className="tab__col tab__col-2">{item.price}</div>
                <div className="tab__col tab__col-3">{item.unit}</div>
              </div>
              <div className="vertLine"></div>
            </div>
          ))}
        </div>
      </div>
      <div className="priceButtonWrapper">
        <Button className="price__button">Рассчитать стоимость</Button>
      </div>
    </>
  );
};

export default Price;
