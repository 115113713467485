import React from "react";

import { useState, useEffect } from "react";

import "./ServicePage.scss";

import MainBlockServices from "../../components/wrappers/MainBlockServices/MainBlockServices";
import OurProjects from "../../components/content_blocks/OurProjects/OurProjects";
import WorkSteps from "../../components/content_blocks/WorkSteps/WorkSteps";
import OurProgress from "../../components/content_blocks/OurProgress/OurProgress";
import CalcPrice from "../../components/content_blocks/CalcPrice/CalcPrice";
import NavSlider from "../../components/ui/NavSlider/NavSlider";
import Contacts from "../../components/content_blocks/Contacts/Contacts";
import AboutService from "../../components/content_blocks/AboutService/AboutService";

import ContentBlockWraper from "../../components/wrappers/ContentBlockWrapper/ContentBlockWrapper";

import ContentCollum from "../../components/wrappers/ContentCollum/ContentCollum";

const ServicePage = ({ service__data, mainBlock__data }) => {
  const contentProps = [
    {
      id: "n1",
      link: "Почему мы?",
      path: "OurProgress",
      className: "ourProgress",
      component: <OurProgress />,
    },
    {
      id: "n2",
      link: "Об услуге",
      path: "AboutService",
      className: "aboutService",
      component: <AboutService data={service__data} />,
    },
    {
      id: "n3",
      link: "Обратная связь",
      path: "CalcPrice",
      className: "calcPrice",
      component: <CalcPrice />,
    },
    {
      id: "n4",
      link: "Примеры работ",
      path: "OurProjects",
      className: "ourProjects",
      component: <OurProjects />,
    },
    {
      id: "n5",
      link: "Этапы работ",
      path: "WorkSteps",
      className: "workSteps",
      component: <WorkSteps />,
    },
    {
      id: "n6",
      link: "Контакты",
      path: "Contacts",
      className: "contacts",
      component: <Contacts />,
    },
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <>
      <div className="content">
        <MainBlockServices {...mainBlock__data} />
        <div className="contentWrapper">
          <NavSlider props={contentProps} />
          <ContentCollum>
            {contentProps.map((content) => {
              return (
                <ContentBlockWraper
                  key={content.path}
                  className={content.className}
                  id={content.path}
                >
                  {content.component}
                </ContentBlockWraper>
              );
            })}
          </ContentCollum>
        </div>
      </div>
    </>
  );
};

export default ServicePage;
