import React from "react";

import { useState, useEffect } from "react";

import "./MainPage.scss";

import MainBlock from "../../components/wrappers/MainBlock/MainBlock";
import OurProgress from "../../components/content_blocks/OurProgress/OurProgress";
import AboutShort from "../../components/content_blocks/AboutShort/AboutShort";
import Services from "../../components/content_blocks/Services/Services";
import OurProjects from "../../components/content_blocks/OurProjects/OurProjects";
import WorkSteps from "../../components/content_blocks/WorkSteps/WorkSteps";
import Reviews from "../../components/content_blocks/Reviews/Reviews";
import Clients from "../../components/content_blocks/Clients/Clients";
import Price from "../../components/content_blocks/Price/Price";
import CalcPrice from "../../components/content_blocks/CalcPrice/CalcPrice";
import Articles from "../../components/content_blocks/Articles/Articles";
import Tags from "../../components/content_blocks/Tags/Tags";
import Contacts from "../../components/content_blocks/Contacts/Contacts";
import NavSlider from "../../components/ui/NavSlider/NavSlider";

import ContentBlockWraper from "../../components/wrappers/ContentBlockWrapper/ContentBlockWrapper";

import ContentCollum from "../../components/wrappers/ContentCollum/ContentCollum";

import test1 from "../../assets/test1.png";

const contentProps = [
  {
    id: "n1",
    link: "Почему мы?",
    path: "OurProgress",
    className: "ourProgress",
    component: <OurProgress />,
  },
  {
    id: "n2",
    link: "О нас",
    path: "AboutShort",
    className: "aboutShort",
    component: <AboutShort />,
  },
  {
    id: "n3",
    link: "Основные услуги",
    path: "Services",
    className: "services",
    component: <Services />,
  },
  {
    id: "n4",
    link: "Примеры работ",
    path: "OurProjects",
    className: "ourProjects",
    component: <OurProjects />,
  },
  {
    id: "n5",
    link: "Этапы работ",
    path: "WorkSteps",
    className: "workSteps",
    component: <WorkSteps />,
  },
  {
    id: "n6",
    link: "Отзывы клиентов",
    path: "Reviews",
    className: "reviews",
    component: <Reviews />,
  },
  {
    id: "n7",
    link: "Наши клиенты",
    path: "Clients",
    className: "clients",
    component: <Clients />,
  },
  {
    id: "n8",
    link: "Цены",
    path: "Price",
    className: "price",
    component: <Price />,
  },
  {
    id: "n9",
    link: "Обратная связь",
    path: "CalcPrice",
    className: "calcPrice",
    component: <CalcPrice />,
  },
  {
    id: "n10",
    link: "Блог",
    path: "Articles",
    className: "articles",
    component: <Articles />,
  },
  {
    id: "n11",
    className: "tags",
    component: <Tags key="Tags" />,
  },
  {
    id: "n12",
    link: "Контакты",
    path: "Contacts",
    className: "contacts",
    component: <Contacts />,
  },
];

const Main = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const mainBlockText = () => {
    if (windowWidth <= 1056) {
      return (
        <>
          Наша команда состоит <br /> из профессионалов с большим опытом работы
          в области демонтажа
        </>
      );
    }
    if (windowWidth <= 1568) {
      return (
        <>
          Наша команда состоит <br />
          из профессионалов с большим опытом работы в области демонтажа. <br />{" "}
          Мы обладаем всеми необходимыми навыками и современным оборудованием
          для выполнения самых сложных задач
        </>
      );
    }
    return (
      <>
        Наша команда состоит из профессионалов с большим опытом работы в области
        демонтажа. Мы обладаем всеми необходимыми навыками и современным
        оборудованием для выполнения самых сложных задач
      </>
    );
  };
  return (
    <>
      <div className="content">
        <MainBlock
          text={mainBlockText()}
          title={"Профессиональный демонтаж зданий"}
          img={test1}
        />
        <div className="contentWrapper">
          <NavSlider props={contentProps} />
          <ContentCollum>
            {contentProps.map((content) => {
              if (content.className === "tags") {
                return content.component;
              } else {
                return (
                  <ContentBlockWraper
                    key={content.path}
                    className={content.className}
                    id={content.path}
                  >
                    {content.component}
                  </ContentBlockWraper>
                );
              }
            })}
          </ContentCollum>
        </div>
      </div>
    </>
  );
};

export default Main;
