import React from "react";
import { useEffect, useState } from "react";

import FooterMenuBlock from "./FooterMenuBlock/FooterMenuBlock";

import { Link } from "react-router-dom";

import "./Footer.scss";

const menuNav = [
  {
    id: "menu1",
    title: "О компании",
    submenu: [
      {
        id: "l11",
        text: "О нас",
        link: "",
      },
      {
        id: "l12",
        text: "Менеджмент",
        link: "",
      },
      {
        id: "l13",
        text: "Контакты",
        link: "",
      },
      {
        id: "l14",
        text: "Лицензии и сертификаты",
        link: "",
      },
      {
        id: "l15",
        text: "Награды",
        link: "",
      },
      {
        id: "l16",
        text: "FAQ",
        link: "",
      },
      {
        id: "l17",
        text: "Отзывы",
        link: "",
      },
      {
        id: "l18",
        text: "Техника с которой работаем",
        link: "",
      },
    ],
  },
  {
    id: "menu2",
    title: "Снос и демонтаж",
    submenu: [
      {
        id: "l21",
        text: "Демонтаж зданий и сооружений",
        link: "services/service_1",
      },
      {
        id: "l22",
        text: "Демонтаж высотных зданий",
        link: "services/service_7",
      },
      {
        id: "l23",
        text: "Демонтаж особо опасных объектов",
        link: "services/service_6",
      },
      {
        id: "l24",
        text: "Демонтаж градирен",
        link: "services/service_16",
      },
      {
        id: "l25",
        text: "Демонтаж заводов",
        link: "services/service_17",
      },
      {
        id: "l26",
        text: "Демонтаж дымовых труб",
        link: "services/service_2",
      },
      {
        id: "l27",
        text: "Демонтаж металлоконструкций",
        link: "services/service_3",
      },
      {
        id: "l28",
        text: "Демонтаж части здания",
        link: "services/service_21",
      },
      {
        id: "l29",
        text: "Демонтаж с сохранением материала",
        link: "services/service_18",
      },
      {
        id: "l210",
        text: "Демонтаж башенных и козловых кранов",
        link: "services/service_15",
      },
      {
        id: "l211",
        text: "Демонтаж монолитных конструкций",
        link: "services/service_19",
      },
      {
        id: "l212",
        text: "Демонтаж незавершенного строительства",
        link: "services/service_20",
      },
      {
        id: "l213",
        text: "Демонтаж мостов и мостовых конструкций",
        link: "services/service_9",
      },
      {
        id: "l214",
        text: "Демонтаж аэропортов и взлетных полос",
        link: "services/service_23",
      },
      {
        id: "l215",
        text: "Демонтаж ферм и силосных ям",
        link: "services/service_25",
      },
      {
        id: "l216",
        text: "Демонтаж ветхого жилья",
        link: "services/service_26",
      },
      {
        id: "l217",
        text: "Демонтаж деревянных домов",
        link: "services/service_27",
      },
      {
        id: "l218",
        text: "Демонтаж бункеров и бомбоубежищ",
        link: "services/service_12",
      },
      {
        id: "l219",
        text: "Демонтаж ангаров",
        link: "services/service_14",
      },
      {
        id: "l220",
        text: "Демонтаж городских зданий",
        link: "services/service_4",
      },
      {
        id: "l221",
        text: "Демонтаж промышленных зданий",
        link: "services/service_5",
      },
    ],
  },
  {
    id: "menu3",
    title: "Земляные работы ",
    submenu: [
      {
        id: "l31",
        text: "Стена в грунте",
        link: "",
      },
      {
        id: "l32",
        text: "Шпунтовое ограждения котлована из металлических труб",
        link: "",
      },
      {
        id: "l33",
        text: "Разработка котлованов",
        link: "",
      },
    ],
  },
  {
    id: "menu4",
    title: "Экологическое сопровождение",
    submenu: [
      {
        id: "l41",
        text: "Переработка строительных отходов",
        link: "",
      },
      {
        id: "l42",
        text: "Рециклинг - переработка железобетона",
        link: "",
      },
      {
        id: "l43",
        text: "Мониторинг",
        link: "",
      },
      {
        id: "l44",
        text: "Рекультивация и санация территорий",
        link: "",
      },
      {
        id: "l45",
        text: "Вторичные материалы. Переработка и продажа",
        link: "",
      },
      {
        id: "l46",
        text: "Разработка природоохранной документации на демонтаж",
        link: "",
      },
    ],
  },
  {
    id: "menu5",
    title: "Другие услуги",
    submenu: [
      {
        id: "l51",
        text: "Разработка котлованов",
        link: "",
      },
      {
        id: "l52",
        text: "Срубка свай",
        link: "",
      },
      {
        id: "l53",
        text: "Рециклинг железобетона",
        link: "",
      },
      {
        id: "l54",
        text: "Благоустройство территорий",
        link: "",
      },
      {
        id: "l55",
        text: "Вывоз и утилизация отходов",
        link: "",
      },
      {
        id: "l56",
        text: "Строительство временных и постоянных дорог",
        link: "",
      },
      {
        id: "l57",
        text: "Реконструкция",
        link: "",
      },
      {
        id: "l58",
        text: "Расчистка русел реки и водоемов",
        link: "",
      },
      {
        id: "l59",
        text: "Отсыпка оврагов",
        link: "",
      },
    ],
  },
];

const Footer = () => {
  const [footerMenuHeight, setFooterMenuHeight] = useState();

  useEffect(() => {
    const handleChange = () => {
      if (window.innerWidth >= 1056) {
        const height = document.getElementById(
          "footerMenuBlock_menu2"
        ).offsetHeight;
        setFooterMenuHeight(height + "px");
      } else {
        setFooterMenuHeight("auto");
      }
    };
    handleChange();
    window.addEventListener("resize", handleChange);

    return () => {
      window.removeEventListener("resize", handleChange);
    };
  });

  return (
    <footer className="footer">
      <div className="footerContent">
        <div className="footer__title">АЛЬТБАЗИС</div>
        <div className="footerRightWrapper">
          <div className="footerTextWrapper">
            <div className="footerTextWrapperTop">
              <div className="footerLinkWrapper footerLinkWrapper-borderBot">
                <Link className="footer__link footer__link-menu" to="/">
                  Главная
                </Link>
              </div>
              <div className="footerLinkWrapper footerLinkWrapper-borderBot">
                <Link className="footer__link footer__link-menu" to="/price">
                  Цены
                </Link>
              </div>
              <div className="footerLinkWrapper footerLinkWrapper-borderBot">
                <Link className="footer__link footer__link-menu" to="/projects">
                  Проекты
                </Link>
              </div>
            </div>
            <div className="footerMenu" style={{ height: footerMenuHeight }}>
              {menuNav.map((item) => {
                return <FooterMenuBlock {...item} key={item.id} />;
              })}
            </div>
          </div>
          <div className="footerLicenseWrapper">
            <div className="footerLinkWrapper footerLinkWrapper-license">
              © ООО «Альтбазис» <br /> Все права защищены 2023
            </div>
            <div className="footerLinkWrapper footerLinkWrapper-license">
              Политика конфеденциальности
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
