import React from "react";

import { Button } from "carbon-components-react";

import "./Reviews.scss";

const Reviews = () => {
  return (
    <>
      <div className="reviews__title contentBlock__title">Отзывы клиентов</div>
      <div className="reviewsContentWrapper">
        <div className="reviews__block">
          <div className="reviews__block__title">Иван Иванов</div>
          <div className="reviews__block__text">
            Я хотел бы выразить свою благодарность и оставить положительный
            отзыв
          </div>
        </div>
        <div className="reviews__block">
          <div className="reviews__block__title">Иван Иванов</div>
          <div className="reviews__block__text">
            Я хотел бы выразить свою благодарность и оставить положительный
            отзыв
          </div>
        </div>
        <div className="reviews__block">
          <div className="reviews__block__title">Иван Иванов</div>
          <div className="reviews__block__text">
            Я хотел бы выразить свою благодарность и оставить положительный
            отзыв
          </div>
        </div>
        <div className="reviews__block">
          <div className="reviews__block__title">Иван Иванов</div>
          <div className="reviews__block__text">
            Я хотел бы выразить свою благодарность и оставить положительный
            отзыв
          </div>
        </div>
        <div className="reviews__block">
          <div className="reviews__block__title">Иван Иванов</div>
          <div className="reviews__block__text">
            Я хотел бы выразить свою благодарность и оставить положительный
            отзыв
          </div>
        </div>
        <div className="reviewsBottomWrapper">
          <div className="reviews__text">
            Еще больше отзывов и благодарственных писем
          </div>
          <Button className="reviews__button">Смотреть кейсы</Button>
        </div>
      </div>
    </>
  );
};

export default Reviews;
