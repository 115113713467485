import React from "react";

import "./Tags.scss";

const Tags = () => {
  return (
    <div className="tags contentBlock">
      <div className="tags__block">Tag 1</div>
      <div className="tags__block">Tag 2</div>
      <div className="tags__block">Tag 3</div>
      <div className="tags__block">Tag 4</div>
      <div className="tags__block">Tag 5</div>
      <div className="tags__block">Tag 6</div>
      <div className="tags__block">Tag 7</div>
      <div className="tags__block">Tag 8</div>
      <div className="tags__block">Tag 9</div>
      <div className="tags__block">Tag 9</div>
    </div>
  );
};

export default Tags;
