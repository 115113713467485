import React from "react";

import "./OurProjectsFinished.scss";

import ProjectBlock from "./ProjectBlock/ProjectBlock";

import project_1 from "../../../assets/OurProjectsFinished/project_1.png";
import project_2 from "../../../assets/OurProjectsFinished/project_2.png";
import project_3 from "../../../assets/OurProjectsFinished/project_3.png";
import project_4 from "../../../assets/OurProjectsFinished/project_4.png";
import project_5 from "../../../assets/OurProjectsFinished/project_5.png";

const ourProjectsFinished__data = [
  {
    id: "project1",
    img: project_1,
    title: "Демонтаж Павлюхина",
    description: (
      <>
        <p>Демонтаж бывшего отдела полиции</p>
        <p>Адрес объекта: ул. Назарбаева</p>
        <p>Даты: 2020 год</p>
        <p>Срок: работы были выполнены за 12 дней</p>
        <p>Заказчик: СК Ак-барс</p>
      </>
    ),
    link: "/projects/project_1",
    rightText: false,
  },
  {
    id: "project2",
    img: project_2,
    title:
      "Демонтаж здания на территории бывшего стекольного завода, г. Туймазы",
    description: (
      <>
        <p>РБ, 2018 год</p>
      </>
    ),
    link: "/projects/project_2",
    rightText: true,
  },
  {
    id: "project3",
    img: project_3,
    title: "Демонтаж школы Мохариши",
    description: (
      <>
        <p>Адрес объекта: Казань, ул. Гаврилова 1</p>
        <p>Заказчик: YIT Corporation</p>
        <p>Даты: 2018 год</p>
      </>
    ),
    link: "/projects/project_3",
    rightText: false,
  },
  {
    id: "project4",
    img: project_4,
    title: "Демонтаж АО «Холод»",
    description: (
      <>
        <p>Адрес объекта: Казань, Халилова 4</p>
        <p>Даты: 2020-2021 год</p>
        <p>Заказчик: Мекан инвест</p>
      </>
    ),
    link: "/projects/project_4",
    rightText: true,
  },
  {
    id: "project5",
    img: project_5,
    title: "Демонтаж на территории бывшего завода Вамин",
    description: (
      <>
        <p> Адрес объекта: Казань</p>
        <p> Даты: 2021 год</p>
        <p> Заказчик: частное лицо</p>{" "}
      </>
    ),
    link: "/projects/project_5",
    rightText: false,
  },
];

const OurProjectsFinished = () => {
  return (
    <>
      <div className="ourProjectsFinished__title contentBlock__title">
        Наши проекты
      </div>
      <div className="ourProjectsFinishedContentWrapper">
        {ourProjectsFinished__data.map((project) => {
          return <ProjectBlock {...project} key={project.id} />;
        })}
      </div>
    </>
  );
};

export default OurProjectsFinished;
