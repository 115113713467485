import React from "react";

import classnames from "classnames";

const ContentBlockWraper = ({ children, id, className }) => {
  return (
    <div className={classnames(className, "contentBlock")} id={id}>
      {children}
    </div>
  );
};

export default ContentBlockWraper;
