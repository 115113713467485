import React from "react";

import "./PricePage.scss";

import MainBlock from "../../components/wrappers/MainBlock/MainBlock";
import Services from "../../components/content_blocks/Services/Services";
import OurProjects from "../../components/content_blocks/OurProjects/OurProjects";
import WorkSteps from "../../components/content_blocks/WorkSteps/WorkSteps";
import Reviews from "../../components/content_blocks/Reviews/Reviews";
import Clients from "../../components/content_blocks/Clients/Clients";
import Price from "../../components/content_blocks/Price/Price";
import CalcPrice from "../../components/content_blocks/CalcPrice/CalcPrice";
import NavSlider from "../../components/ui/NavSlider/NavSlider";

import ContentBlockWraper from "../../components/wrappers/ContentBlockWrapper/ContentBlockWrapper";

import ContentCollum from "../../components/wrappers/ContentCollum/ContentCollum";

import contactsMainBlock from "../../assets/contactsMainBlock.png";

const mainBlockText =
  "Уточнить актуальность цен и узнать стоимость работ по вашему проекту вы можете связавшись с нами";

const contentProps = [
  {
    id: "n1",
    link: "Цены",
    path: "Price",
    className: "price",
    component: <Price />,
  },
  {
    id: "n2",
    link: "Основные услуги",
    path: "Services",
    className: "services",
    component: <Services />,
  },
  {
    id: "n3",
    link: "Примеры работ",
    path: "OurProjects",
    className: "ourProjects",
    component: <OurProjects />,
  },
  {
    id: "n4",
    link: "Этапы работ",
    path: "WorkSteps",
    className: "workSteps",
    component: <WorkSteps />,
  },
  {
    id: "n5",
    link: "Отзывы клиентов",
    path: "Reviews",
    className: "reviews",
    component: <Reviews />,
  },
  {
    id: "n6",
    link: "Наши клиенты",
    path: "Clients",
    className: "clients",
    component: <Clients />,
  },
  {
    id: "n9",
    link: "Обратная связь",
    path: "CalcPrice",
    className: "calcPrice",
    component: <CalcPrice />,
  },
];

const PricePage = () => {
  return (
    <>
      <div className="content">
        <MainBlock
          text={mainBlockText}
          title={"Цены на наши услуги"}
          img={contactsMainBlock}
        />
        <div className="contentWrapper">
          <NavSlider props={contentProps} />
          <ContentCollum>
            {contentProps.map((content) => {
              return (
                <ContentBlockWraper
                  key={content.path}
                  className={content.className}
                  id={content.path}
                >
                  {content.component}
                </ContentBlockWraper>
              );
            })}
          </ContentCollum>
        </div>
      </div>
    </>
  );
};

export default PricePage;
