import React from "react";

import "./OurProgress.scss";

const OurProgress = () => {
  return (
    <>
      <div className="ourProgress__title contentBlock__title">
        Наши достижения в цифрах
      </div>
      <div className="ourProgressContent">
        <div className="ourProgressBlock">
          <div className="ourProgress__number">{">"}10</div>
          <div className="ourProgress__text">лет опыта работы</div>
        </div>
        <div className="vertLine vertLine-mobile"></div>
        <div className="ourProgressBlock">
          <div className="ourProgress__number">{">"}50</div>
          <div className="ourProgress__text">реализованных проектов</div>
        </div>
        <div className="vertLine vertLine-mobile"></div>

        <div className="ourProgressBlock">
          <div className="ourProgress__number">{">"}20</div>
          <div className="ourProgress__text">довольных клиентов</div>
        </div>
      </div>
    </>
  );
};

export default OurProgress;
