import React from "react";

import "./Contacts.scss";

import map from "../../../assets/map.png";

const Contacts = () => {
  return (
    <>
      <div className="contacts__title contentBlock__title">Контакты</div>
      <div className="contactsContentWrapper">
        <div className="contactsTextWrapper">
          <div className="contacts__block">
            <div className="contacts__block__title">Адрес главного офиса</div>
            <div className="contacts__block__text">
              г. Казань, ул. Маршала Чуйкова, д. 2Д, оф. 313
            </div>
          </div>
          <div className="contacts__block">
            <div className="contacts__block__title">Режим работы</div>
            <div className="contacts__block__text">
              ПН-ПТ: 9:00-18:00 <br />
              СБ-ВС - выходной
            </div>
          </div>
          <div className="contacts__block">
            <div className="contacts__block__title">Телефон</div>
            <div className="contacts__block__text">8 (800) 700 10-28</div>
          </div>
          <div className="contacts__block">
            <div className="contacts__block__title">Электронная почта</div>
            <div className="contacts__block__text">iinfo@altbazis.ru</div>
          </div>
        </div>
        <img className="contacts__map" src={map} alt="map" />
      </div>
    </>
  );
};

export default Contacts;
