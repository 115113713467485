import React from "react";

import { useState } from "react";

import "./Faq.scss";

import FaqBlock from "./FaqBlock/FaqBlock";

const faq_data = [
  {
    id: "1",
    question: "Каковы основные преимущества демонтажа сооружений и зданий?",
    answer:
      "Основные преимущества демонтажа сооружений и зданий включают освобождение территории под новые строительные проекты, возможность утилизации и повторного использования материалов, снижение рисков старых или поврежденных конструкций, а также содействие в экологической реконструкции.",
  },
  {
    id: "2",
    question:
      "Каким образом происходит процесс демонтажа и сколько времени обычно занимает?",
    answer:
      "Процесс демонтажа обычно происходит в несколько этапов: подготовительные работы, разборка конструкции, утилизация материалов. Время зависит от сложности объекта и используемой техники, но в среднем может занимать от нескольких дней до нескольких месяцев.",
  },
  {
    id: "3",
    question: "Какие типы сооружений и зданий подлежат демонтажу?",
    answer:
      "Демонтаж может быть применен для различных типов сооружений и зданий, включая жилые дома, промышленные сооружения, мосты, шахты и другие объекты.",
  },
  {
    id: "4",
    question:
      "Какую специальную технику и оборудование используют для демонтажа сооружений?",
    answer:
      "Для демонтажа сооружений используется специализированная техника, такая как экскаваторы с гидромолотами, резчики бетона, краны с высотными подъемниками и другие инструменты.",
  },
  {
    id: "5",
    question:
      "Каковы стандарты безопасности и лицензии, которые ваша компания имеет для выполнения демонтажных работ?",
    answer:
      "Компания должна обладать соответствующими лицензиями и сертификатами на проведение демонтажных работ, а также строго соблюдать стандарты безопасности и окружающей среды.",
  },
  {
    id: "6",
    question:
      "Какие материалы утилизируются при демонтаже, а какие можно сохранить для дальнейшего использования?",
    answer:
      "При демонтаже сооружений утилизируются материалы, которые не могут быть сохранены для повторного использования, например, старые бетонные блоки. Однако, многие материалы, такие как металлы, дерево и камень, могут быть сохранены и переработаны.",
  },
  {
    id: "7",
    question:
      "Как влияет демонтаж сооружений на окружающую среду и как ваша компания заботится об экологической ответственности?",
    answer:
      "Демонтаж сооружений может повлиять на окружающую среду, особенно если не проводиться правильная утилизация отходов. Мы обеспечиваем правильную сортировку и переработку отходов для минимизации воздействия на природу.",
  },
  {
    id: "8",
    question: "Какие факторы влияют на стоимость демонтажных работ?",
    answer:
      "Стоимость демонтажных работ зависит от множества факторов, включая размер и сложность объекта, доступность строительной площадки, используемую технику и объем утилизации материалов.",
  },
  {
    id: "9",
    question:
      "Какую подготовку требуют объекты перед демонтажом и есть ли необходимость в дополнительных разрешениях или документах?",
    answer:
      "Перед демонтажом объект должен быть тщательно исследован, возможно требуется получение разрешений и документов от соответствующих органов.",
  },
  {
    id: "10",
    question:
      "Какие референции и отзывы у вас есть от предыдущих клиентов, которые выполняли демонтажные работы?",
    answer:
      "Наша компания может предоставить вам референции и отзывы от предыдущих клиентов, которые убедят вас в нашем профессионализме и качестве выполненных работ. Мы гордимся нашими довольными клиентами и успешными проектами.",
  },
];

const Faq = () => {
  const [activeFaq, setActiveFaq] = useState();

  return (
    <>
      <div className="faq__title contentBlock__title">FAQ</div>
      {faq_data.map((block) => {
        return (
          <FaqBlock
            {...block}
            activeFaq={activeFaq}
            setActiveFaq={setActiveFaq}
            key={"faqBlock" + block.id}
          />
        );
      })}
    </>
  );
};

export default Faq;
