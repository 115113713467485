import React from "react";

import "./ContactsPage.scss";

import MainBlock from "../../components/wrappers/MainBlock/MainBlock";
import CalcPrice from "../../components/content_blocks/CalcPrice/CalcPrice";
import Contacts from "../../components/content_blocks/Contacts/Contacts";
import NavSlider from "../../components/ui/NavSlider/NavSlider";

import ContentBlockWraper from "../../components/wrappers/ContentBlockWrapper/ContentBlockWrapper";

import ContentCollum from "../../components/wrappers/ContentCollum/ContentCollum";

import contactsMainBlock from "../../assets/contactsMainBlock.png";

const mainBlockText =
  "Вы можете связаться любым удобным для вас способом или оставить заявку и наш менеджер перезвонит вам";

const contentProps = [
  {
    id: "n1",
    link: "Контакты",
    path: "Contacts",
    className: "contacts",
    component: <Contacts />,
  },
  {
    id: "n2",
    link: "Обратная связь",
    path: "CalcPrice",
    className: "calcPrice",
    component: <CalcPrice />,
  },
];

const ContactsPage = () => {
  return (
    <>
      <div className="content">
        <MainBlock
          text={mainBlockText}
          title={"Мы всегда на связи!"}
          img={contactsMainBlock}
        />
        <div className="contentWrapper">
          <NavSlider props={contentProps} />
          <ContentCollum>
            {contentProps.map((content) => {
              return (
                <ContentBlockWraper
                  key={content.path}
                  className={content.className}
                  id={content.path}
                >
                  {content.component}
                </ContentBlockWraper>
              );
            })}
          </ContentCollum>
        </div>
      </div>
    </>
  );
};

export default ContactsPage;
