import React from "react";

import "./AboutFull.scss";

import aboutFull from "../../../assets/aboutFull.png";

const AboutFull = () => {
  return (
    <>
      <div className="aboutFull__title contentBlock__title">О компании</div>
      <div className="aboutFullContentWrapper">
        <div className="aboutFullInfoWrapper">
          <div className="aboutFullIntroWrapper">
            <p className="aboutFull__intro">
              ООО "АЛЬТБАЗИС" обладает богатым опытом в области демонтажа зданий
              и сооружений
            </p>
          </div>
          <p className="aboutFull__text aboutFull__text-1">
            ООО "АЛЬТБАЗИС" имеет прочную репутацию в сфере демонтажных работ.
            Мы стремимся к постоянному развитию и применению передовых
            технологий в своей деятельности. Наша компания готова предоставить
            надежные и профессиональные услуги демонтажа
          </p>
          <p className="aboutFull__text aboutFull__text-2">
            Команда ООО "АЛЬТБАЗИС" готова принять участие в масштабных проектах
            модернизации производственных мощностей, а также сотрудничать с
            девелоперскими проектами. Мы также активно участвуем в приоритетных
            программах, реализуемых при поддержке органов власти на уровне
            Российской Федерации и субъектов РФ.
          </p>
          <p className="aboutFull__text aboutFull__text-3">
            Мы специализируемся на высотном демонтаже, отключении и ликвидации
            инженерных сетей и коммуникаций, а также проведении сноса домов,
            фабрик, заводов, мостов и путепроводов. Качество и безопасность
            работ - наш приоритет
          </p>
          <p className="aboutFull__text aboutFull__text-4">
            Выбрав ООО "АЛЬТБАЗИС", вы получите надежного партнера, готового
            выполнить демонтажные работы качественно и профессионально
          </p>
        </div>
        <img className="aboutFull__img" src={aboutFull} alt="aboutFull" />
      </div>
    </>
  );
};

export default AboutFull;
