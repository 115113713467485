import React, { useState, useContext, useCallback, useEffect } from "react";
import { loadServices } from "../API/apiRequests";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [visibleContent, setVisibleContent] = useState();

  // Fetch from api
  const [servicesData, setServicesData] = useState([]);

  const getServicesData = useCallback(async () => {
    const data = await loadServices();
    setServicesData(data.data[0].attributes.allServices);
  }, []);

  useEffect(() => {
    getServicesData();
  }, [getServicesData]);

  return (
    <AppContext.Provider
      value={{
        visibleContent,
        setVisibleContent,
        servicesData,
        setServicesData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};
export { AppContext, AppProvider };
