import React from "react";

import LinkMore from "../../reusable/LinkMore/LinkMore";

import "./AboutShort.scss";

import about from "../../../assets/about.png";

const AboutShort = () => {
  return (
    <>
      <div className="aboutShort__title contentBlock__title">О компании</div>
      <div className="aboutShortContentWrapper">
        <div className="aboutShortInfoWrapper">
          <div className="aboutShort__topText">
            ООО "АЛЬТБАЗИС" обладает богатым опытом в области демонтажа зданий и
            сооружений
          </div>
          <div>
            <div className="aboutShort__text">
              Наша команда специалистов имеет многолетний опыт работы с
              различными типами проектов, включая комплексные задачи высотного
              демонтажа, ликвидацию инженерных сетей и снос различных строений.
              Наш опыт позволяет нам эффективно решать любые технические и
              организационные задачи, которые возникают в процессе демонтажа
            </div>
            <LinkMore path="/about" />
          </div>
        </div>
        <img className="aboutShort__img" src={about} alt="about" />
      </div>
    </>
  );
};

export default AboutShort;
