import React from "react";

import { useState, useEffect } from "react";

import company1 from "../../../assets/company1.png";
import company2 from "../../../assets/company2.png";
import company3 from "../../../assets/company3.png";
import company4 from "../../../assets/company4.png";

import "./Clients.scss";

const Clients = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <>
      <div className="clients__title contentBlock__title">
        Наши {windowWidth <= 600 ? <br /> : ``} компании-клиенты
      </div>
      <div className="clientsContentWrapper">
        <div className="clients__block">
          <img src={company1} alt="company1" />
        </div>
        <div className="clients__block">
          <img src={company2} alt="company2" />
        </div>
        <div className="clients__block">
          <img src={company3} alt="company3" />
        </div>
        <div className="clients__block">
          <img src={company4} alt="company4" />
        </div>
      </div>
    </>
  );
};

export default Clients;
