// const baseUrl = "http://localhost:1234/api";
const baseUrl = "https://rus-demontag-strapi.onrender.com/api";

export const createRequest =
  (path) =>
  async (urlParams = null) => {
    const url = urlParams
      ? `${baseUrl}${path}?` + new URLSearchParams(urlParams)
      : `${baseUrl}${path}`;
    try {
      const response = await fetch(url);
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.log("There was an error", error);
    }
  };
