import React from "react";

import "./ProjectsPage.scss";

import MainBlock from "../../components/wrappers/MainBlock/MainBlock";
import OurProgress from "../../components/content_blocks/OurProgress/OurProgress";

import Reviews from "../../components/content_blocks/Reviews/Reviews";
import Clients from "../../components/content_blocks/Clients/Clients";
import OurProjectsFinished from "../../components/content_blocks/OurProjectsFinished/OurProjectsFinished";
import CalcPrice from "../../components/content_blocks/CalcPrice/CalcPrice";
import Contacts from "../../components/content_blocks/Contacts/Contacts";
import NavSlider from "../../components/ui/NavSlider/NavSlider";

import ContentBlockWraper from "../../components/wrappers/ContentBlockWrapper/ContentBlockWrapper";

import ContentCollum from "../../components/wrappers/ContentCollum/ContentCollum";

import test1 from "../../assets/test1.png";

const mainBlockText = "";

const contentProps = [
  {
    id: "n1",
    link: "Почему мы?",
    path: "OurProgress",
    className: "ourProgress",
    component: <OurProgress />,
  },
  {
    id: "n2",
    link: "Наши проекты",
    path: "OurProjectsFinished",
    className: "ourProjectsFinished",
    component: <OurProjectsFinished />,
  },
  {
    id: "n3",
    link: "Отзывы клиентов",
    path: "Reviews",
    className: "reviews",
    component: <Reviews />,
  },
  {
    id: "n4",
    link: "Наши клиенты",
    path: "Clients",
    className: "clients",
    component: <Clients />,
  },
  {
    id: "n5",
    link: "Обратная связь",
    path: "CalcPrice",
    className: "calcPrice",
    component: <CalcPrice />,
  },
  {
    id: "n6",
    link: "Контакты",
    path: "Contacts",
    className: "contacts",
    component: <Contacts />,
  },
];

const ProjectsPage = () => {
  return (
    <>
      <div className="content">
        <MainBlock text={mainBlockText} title={"Наши проекты"} img={test1} />
        <div className="contentWrapper">
          <NavSlider props={contentProps} />
          <ContentCollum>
            {contentProps.map((content) => {
              return (
                <ContentBlockWraper
                  key={content.path}
                  className={content.className}
                  id={content.path}
                >
                  {content.component}
                </ContentBlockWraper>
              );
            })}
          </ContentCollum>
        </div>
      </div>
    </>
  );
};

export default ProjectsPage;
