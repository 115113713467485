import "./styles/App.scss";

import { Routes, Route } from "react-router-dom";
import { useGlobalContext } from "./context/contex";

import HeaderUI from "./components/ui/HeaderUI/HeaderUI";
import PopUp from "./components/modal/PopUp/PopUp";
import Footer from "./components/ui/Footer/Footer";

import Main from "./pages/Main/MainPage";
import About from "./pages/About/AboutPage";
import Contacts from "./pages/Contacts/ContactsPage";
import Faq from "./pages/Faq/FaqPage";
import Price from "./pages/Price/PricePage";
import Projects from "./pages/Projects/ProjectsPage";
import Project1 from "./pages/Projects/Project1Page/Project1Page";
import Project2 from "./pages/Projects/Project2Page/Project2Page";
import Project3 from "./pages/Projects/Project3Page/Project3Page";
import Project4 from "./pages/Projects/Project4Page/Project4Page";
import Project5 from "./pages/Projects/Project5Page/Project5Page";
import Service from "././pages/SingleService/ServicePage";

function App() {
  const { servicesData } = useGlobalContext();
  const data_services = [];

  servicesData.map((item) => {
    data_services.push({
      id: item.Title,
      mainBlock__data: {
        title: item.Title,
        text: item.Description,
        img: item.Img.data.attributes.url,
      },
      service__data: [],
    });
    return Object.values(item.TextBlocks).map((textBlock) => {
      if (textBlock.Img.data) {
        data_services[data_services.length - 1].service__data.push({
          id: textBlock.id,
          title: textBlock.Title,
          text: textBlock.Text,
          img: textBlock.Img.data.attributes.url,
          imageLeftSided: textBlock.ImageLeftSided,
        });
      } else {
        data_services[data_services.length - 1].service__data.push({
          id: textBlock.id,
          title: textBlock.Title,
          text: textBlock.Text,
        });
      }
    });
  });

  return (
    <>
      <HeaderUI />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/price" element={<Price />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/project_1" element={<Project1 />} />
        <Route path="/projects/project_2" element={<Project2 />} />
        <Route path="/projects/project_3" element={<Project3 />} />
        <Route path="/projects/project_4" element={<Project4 />} />
        <Route path="/projects/project_5" element={<Project5 />} />
        {data_services.map((page) => {
          return (
            <Route
              key={page.id}
              path={`services/${page.id}`}
              element={<Service {...page} />}
            />
          );
        })}
      </Routes>
      <PopUp />
      <Footer />
    </>
  );
}

export default App;
