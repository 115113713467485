import React from "react";

import { useState, useEffect } from "react";

import "./AboutPage.scss";

import MainBlock from "../../components/wrappers/MainBlock/MainBlock";
import NavSlider from "../../components/ui/NavSlider/NavSlider";
import AboutFull from "../../components/content_blocks/AboutFull/AboutFull";
import OurProjects from "../../components/content_blocks/OurProjects/OurProjects";
import Reviews from "../../components/content_blocks/Reviews/Reviews";
import Clients from "../../components/content_blocks/Clients/Clients";
import CalcPrice from "../../components/content_blocks/CalcPrice/CalcPrice";

import ContentBlockWraper from "../../components/wrappers/ContentBlockWrapper/ContentBlockWrapper";

import ContentCollum from "../../components/wrappers/ContentCollum/ContentCollum";

import test1 from "../../assets/test1.png";

const contentProps = [
  {
    id: "n1",
    link: "О компании",
    path: "AboutFull",
    className: "aboutFull",
    component: <AboutFull />,
  },
  {
    id: "n2",
    link: "Примеры работ",
    path: "OurProjects",
    className: "ourProjects",
    component: <OurProjects />,
  },
  {
    id: "n3",
    link: "Отзывы клиентов",
    path: "Reviews",
    className: "reviews",
    component: <Reviews />,
  },
  {
    id: "n4",
    link: "Наши клиенты",
    path: "Clients",
    className: "clients",
    component: <Clients />,
  },
  {
    id: "n5",
    link: "Обратная связь",
    path: "CalcPrice",
    className: "calcPrice",
    component: <CalcPrice />,
  },
];

const About = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const mainBlockText = () => {
    if (windowWidth <= 1056) {
      return (
        <>
          ООО «АЛЬТБАЗИС» – <br /> специализированная компания, предоставляющая
          профессиональные услуги по демонтажу зданий
        </>
      );
    }
    if (windowWidth <= 1568) {
      return (
        <>
          ООО «АЛЬТБАЗИС» – <br /> специализированная компания, предоставляющая
          профессиональные услуги по демонтажу зданий. Мы обладаем значительным
          опытом в сфере сноса и демонтажа зданий в Казани и по всей России.
          Наша компания успешно выполняет проекты различного масштаба и
          сложности
        </>
      );
    }
    return (
      <>
        ООО «АЛЬТБАЗИС» – специализированная компания, предоставляющая
        профессиональные услуги по демонтажу зданий. Мы обладаем значительным
        опытом в сфере сноса и демонтажа зданий в Казани и по всей России. Наша
        компания успешно выполняет проекты различного масштаба и сложности
      </>
    );
  };

  return (
    <>
      <div className="content">
        <MainBlock
          text={mainBlockText()}
          title={"Профессиональный демонтаж зданий"}
          img={test1}
        />
        <div className="contentWrapper">
          <NavSlider props={contentProps} />
          <ContentCollum>
            {contentProps.map((content) => {
              return (
                <ContentBlockWraper
                  key={content.path}
                  className={content.className}
                  id={content.path}
                >
                  {content.component}
                </ContentBlockWraper>
              );
            })}
          </ContentCollum>
        </div>
      </div>
    </>
  );
};

export default About;
