import React from "react";

import ContentBlockGrey from "../../wrappers/ContentBlockGrey/ContentBlockGrey";

import demolish from "../../../assets/demolish.png";
import ground from "../../../assets/ground.png";
import eco from "../../../assets/eco.png";

import "./Services.scss";

const services = [
  {
    id: "service1",
    title: "Снос и демонтаж",
    img: demolish,
    img_alt: "demolish",
    text: (
      <ol type="1">
        <li>1. Освобождение пространства от ненужных зданий и сооружений</li>
        <li>2. Подготовка площадок для новых строительных проектов</li>
        <li>3. Ликвидация устаревших или поврежденных конструкций</li>
      </ol>
    ),
    link: "#",
  },
  {
    id: "service2",
    title: "Земляные работы",
    img: ground,
    img_alt: "ground",

    text: (
      <ol type="1">
        <li>1. Земляные работы для подготовки строительных участков</li>
        <li>
          2. Копка котлованов, траншей и фундаментов с использованием
          современной техники
        </li>
        <li>
          3. Устройство дренажных систем и регулирование уровня грунтовых вод
        </li>
      </ol>
    ),
    link: "#",
    middle: true,
  },
  {
    id: "service3",
    title: "Экологическое сопровождение",
    img: eco,
    img_alt: "eco",

    text: (
      <ol type="1">
        <li>
          1. Комплексные экологические исследования и оценка воздействия на
          окружающую среду
        </li>
        <li>
          2. Разработка и реализация мероприятий по снижению негативного
          воздействия на природные ресурсы
        </li>
        <li>3. Ликвидация устаревших или поврежденных конструкций</li>
      </ol>
    ),
    link: "#",
  },
];

const Services = () => {
  return (
    <>
      <div className="services__title contentBlock__title">
        Список основных услуг
      </div>
      <div className="servicesContent">
        {services.map((service) => {
          return <ContentBlockGrey {...service} key={service.id} />;
        })}
      </div>
    </>
  );
};

export default Services;
