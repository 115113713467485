import React from "react";
import { useState, useEffect } from "react";

// import { Link } from "react-router-dom";

import { Button, HeaderGlobalBar } from "carbon-components-react";

import BurgerMenuBlock from "./BurgerMenuBlock/BurgerMenuBlock";

import HeaderNavigation from "./HeaderNavigation/HeaderNavigation";

import { Menu } from "@carbon/icons-react";

import "./HeaderUI.scss";

import { useGlobalContext } from "../../../context/contex";

const HeaderUI = () => {
  const { servicesData } = useGlobalContext();

  const categories = new Map([
    ["Снос и демонтаж", "dismantling"],
    ["Земляные работы", "groundWorks"],
    ["Экологическое сопровождение", "ecologicalServices"],
    ["Другие услуги", "otherServices"],
  ]);

  const menuData = [
    {
      id: "menuLink_1",
      link: "Главная",
      path: "/",
    },
    {
      id: "menuLink_2",
      link: "О компании",
      nested: false,
      menuItems: [
        {
          id: "menuItem_1_1",
          text: "О нас",
          link: "/about",
        },
        {
          id: "menuItem_1_2",
          text: "Менеджмент",
          link: "",
        },
        {
          id: "menuItem_1_3",
          text: "Контакты",
          link: "/contacts",
        },
        {
          id: "menuItem_1_4",
          text: "Лицензии и сертификаты",
          link: "",
        },
        {
          id: "menuItem_1_5",
          text: "Награды",
          link: "",
        },
        {
          id: "menuItem_1_6",
          text: "FAQ",
          link: "/faq",
        },
        {
          id: "menuItem_1_7",
          text: "Отзывы",
          link: "",
        },
        {
          id: "menuItem_1_8",
          text: "Техника с которой работаем",
          link: "",
        },
      ],
    },
    {
      id: "menuLink_3",
      link: "Услуги",
      nested: true,
      menuItems: {
        dismantling: {
          menu: "dismantling",
          text: "Снос и демонтаж",
          submenu: [],
        },
        groundWorks: {
          menu: "groundWorks",
          text: "Земляные работы",
          submenu: [],
        },
        ecologicalServices: {
          menu: "ecologicalServices",
          text: "Экологическое сопровождение",
          submenu: [],
        },
        otherServices: {
          menu: "otherServices",
          text: "Другие услуги",
          submenu: [],
        },
      },
    },
    {
      id: "menuLink_4",
      link: "Цены",
      path: "/price",
    },
    {
      id: "menuLink_5",
      link: "Проекты",
      path: "/projects",
    },
  ];

  const menuDataMobile = {
    aboutCompany: {
      menu: "aboutCompany",
      text: "О компании",
      submenu: [
        {
          id: "l11",
          text: "О нас",
          link: "/about",
        },
        {
          id: "l12",
          text: "Менеджмент",
          link: "",
        },
        {
          id: "l13",
          text: "Контакты",
          link: "/contacts",
        },
        {
          id: "l14",
          text: "Лицензии и сертификаты",
          link: "",
        },
        {
          id: "l15",
          text: "Награды",
          link: "",
        },
        {
          id: "l16",
          text: "FAQ",
          link: "/faq",
        },
        {
          id: "l17",
          text: "Отзывы",
          link: "",
        },
        {
          id: "l18",
          text: "Техника с которой работаем",
          link: "",
        },
      ],
    },
    dismantling: {
      menu: "dismantling",
      text: "Снос и демонтаж",
      submenu: [],
    },
    groundWorks: {
      menu: "groundWorks",
      text: "Земляные работы",
      submenu: [],
    },
    ecologicalServices: {
      menu: "ecologicalServices",
      text: "Экологическое сопровождение",
      submenu: [],
    },
    otherServices: {
      menu: "otherServices",
      text: "Другие услуги",
      submenu: [],
    },
  };

  servicesData.map((item) => {
    menuData[2].menuItems[categories.get(item.Category)].submenu.push({
      id: item.id,
      text: item.Title,
      link: item.Title,
    });
  });

  servicesData.map((item) => {
    menuDataMobile[categories.get(item.Category)].submenu.push({
      id: item.id,
      text: item.Title,
      link: item.Title,
    });
  });

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

  const burgerSubMenus = document.getElementsByClassName("headerBurgerSubMenu");

  const changeBurgerMenuState = () => {
    setBurgerMenuOpen(!burgerMenuOpen);
    if (!burgerMenuOpen) {
      for (var i = 0; i < burgerSubMenus.length; i++) {
        burgerSubMenus[i].classList.remove("headerBurgerSubMenu-open");
      }
    }
  };

  const openPopUp = () => {
    document.getElementById("PopUp").classList.toggle("popUp-open");
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth >= 1280 && burgerMenuOpen != false) {
        setBurgerMenuOpen(false);
      }
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <header className="headerWrapper">
      <div className="header">
        <button className="headerBurgerBtn" onClick={changeBurgerMenuState}>
          <Menu />
        </button>
        <a className="header__name" prefix="">
          АЛЬТБАЗИС
        </a>
        <HeaderNavigation menuItems={menuData} />
        <HeaderGlobalBar className="headerGlobal">
          <Button className="header__calcBtn" onClick={openPopUp}>
            Рассчитать стоимость
          </Button>
        </HeaderGlobalBar>
        {burgerMenuOpen && (
          <div
            className="headerBurgerMenu headerBurgerMenu-open"
            id="burgerMenu"
          >
            <a className="header__burgerLink" href="/">
              Главная
            </a>
            <div className="vertLine vertLine-burger"></div>
            {Object.values(menuDataMobile).map((item) => (
              <BurgerMenuBlock {...item} key={item.menu} />
            ))}
            <a className="header__burgerLink" href="/price">
              Цены
            </a>
            <a className="header__burgerLink" href="/projects">
              Проекты
            </a>
          </div>
        )}
        <div
          className={
            burgerMenuOpen
              ? "headerFadeScreen headerFadeScreen-open"
              : "headerFadeScreen"
          }
          id="headerFadeScreen"
        ></div>
      </div>
    </header>
  );
};

export default HeaderUI;
